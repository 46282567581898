import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Source_Sans_3\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"sourceSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager" */ "/app/public/sgf-flag.svg");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
